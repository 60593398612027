import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import {Hero, InsightCardsGrid, TwoVerticalContentBlocks} from '../components/organisms';
import {SingleBreadcrumb, Divider} from '../components/common';
import {useInsights, useStages} from '../static_queries';
import {LayoutContainer, Column} from '../components/layouts';
import LayoutGenerator from '../helpers/layoutGenerator';

/**
 * @description Page template for 'stage' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Stage page React element.
 */
const Stage = ({pageContext}) => {

  if (typeof window === 'undefined') { // Ensure proper server rendering.
    return null;
  }
  let [currentPagination, setCurrentPagination] = useState(1);
  let [maxPagination, setMaxPagination] = useState(1);

  const pageWidth = window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth; // TODO: Move that to context to pass it as props to other components

  const pageSpeciesName = pageContext.taxonomies.filter(tax =>
    tax.__typename === 'ContentfulSpecies')[0].speciesName;
  const pageLifestageName = pageContext.taxonomies.filter(tax =>
    tax.__typename === 'ContentfulLifestage')[0].lifestageName;

  const stages = useStages().filter(({node}) => {
    const nodeSpeciesName = node.taxonomies.filter(tax =>
      tax.__typename === 'ContentfulSpecies')[0].speciesName;
    const nodeLifestageName = node.taxonomies.filter(tax =>
      tax.__typename === 'ContentfulLifestage')[0].lifestageName;
    return nodeSpeciesName === pageSpeciesName &&
      nodeLifestageName === pageLifestageName &&
      node.stageNumber !== null && // TODO: Needs to refactor that, probably change the taxonmy levels
      (node.stageNumber + 1 === pageContext.stageNumber ||
        node.stageNumber - 1 === pageContext.stageNumber);
  }).map(({node}) => {
    return {
      ...node, // Remove node property to ensure data structure is correct
      __typename: 'ContentfulStage', // Add typename to ensure data structure is correct
      buttonLabel: node.stageNumber > pageContext.stageNumber ?
        'Next stage' :
        'Previous stage' // Add button label as it needs diefferent ones
    };
  });

  const insights = useInsights().filter(({node}) => {
    const nodeSpeciesName = node.taxonomies.filter(tax => tax.__typename === 'ContentfulSpecies')[0].speciesName;
    const nodeStage = node.taxonomies.filter(tax => tax.__typename === 'ContentfulStage')[0].title;
    const nodelifestage = node.taxonomies.filter(tax => tax.__typename === 'ContentfulLifestage')[0].lifestageName;
    let currentStages = () => {
      if (pageContext.subStages) {
        return pageContext.subStages.map(item => item.title);
      }
      else {
        return [];
      }
    };
    return nodeSpeciesName === pageSpeciesName &&
      (nodeStage === pageContext.title || currentStages().includes(nodeStage)) &&
      nodelifestage === pageLifestageName;
  }).map(({node}) => node);

  return (
    <PageWrapper pageContext={pageContext}>

      <LayoutContainer columns="one" additionalClass="rc-max-width--xl">
        <Column additionalClass="rc-padding-bottom--none">
          <div className="rc-margin-top--xs--desktop rc-margin-top--xl--mobile
             rc-margin-left--lg--desktop">
            <SingleBreadcrumb
              label={`${pageSpeciesName} stages`}
              link={`/${pageSpeciesName.toLowerCase()}/explore-stages`}/>
          </div>
        </Column>
      </LayoutContainer>

      <Hero
        containerClass="rc-margin-top--md--mobile"
        title={pageContext.title}
        summary={pageContext.summary.summary}
        imageData={pageContext.image}
        orderMobile="regular"/>

      <LayoutContainer additionalClass="rc-max-width--xl">
        <Column>
          <InsightCardsGrid
            cards={insights}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            maxPagination={maxPagination}
            setMaxPagination={setMaxPagination}
            pageWidth={pageWidth}/>
        </Column>
      </LayoutContainer>

      <TwoVerticalContentBlocks data={stages}/>

      <Divider additionalClass="rc-margin-bottom--lg"/>

      {pageContext.layoutContainer && <LayoutGenerator layouts={pageContext.layoutContainer} />}
    </PageWrapper>
  );
};

Stage.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Stage;
