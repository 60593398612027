import React from 'react';
import {HorizontalContentBlock, Hero, TwoVerticalContentBlocks, QuantInsightStars} from '../components/organisms';
import setButtonConfig from '../helpers/setButtonConfig';

/**
 * @description Method to generate correct content block. Choose between horizontal, vertical and hero.
 * @param {array} layouts Array of objects with information for each content block type.
 * @param {DOMElement} children Any valid DOM element passed as a children of block.
 * @return {array} Array of React DOM elements.
 */
const LayoutGenerator = ({layouts, children}) => {
  let markup = [];

  if (layouts === null) {
    return null;
  }

  if (layouts.length > 0) {
    layouts.forEach((layout, index) => {

      let dataSource = null;
      if (typeof layout.columns !== 'undefined' && layout.columns !== null && layout.columns.length > 0) {
        dataSource = layout.columns[0];
      }
      else {
        dataSource = layout;
      }
      const {layoutStyle, id} = layout;
      const {cardImage, cardTitle, cardSummary, id: dataSourceId} = dataSource;
      let buttons = [];

      buttons.push(setButtonConfig({
        typeName: dataSource.__typename,
        fields: dataSource.fields,
        cardTitle: dataSource.cardTitle,
        buttonLabel: dataSource.buttonLabel
      }));

      switch (layoutStyle) {

        // make these human readable
        case 'statement--list':
          markup.push(
            <QuantInsightStars key={id} data={layout} />
          );
          break;

        case 'image--left':
          markup.push(
            <HorizontalContentBlock
              key={id || dataSourceId}
              image={dataSource.cardImage}
              summary={dataSource.cardSummary}
              buttonsConfig={buttons}
              title={dataSource.cardTitle}
              order='reverted'/>
          );
          break;

        case 'image--right':
          markup.push(
            <HorizontalContentBlock
              key={id || dataSourceId}
              image={dataSource.cardImage}
              summary={dataSource.cardSummary}
              buttonsConfig={buttons}
              title={dataSource.cardTitle} />
          );
          break;

        case 'image--top':
          markup.push(
            <TwoVerticalContentBlocks
              key={id || dataSourceId}
              image={dataSource.cardImage}
              summary={dataSource.cardSummary}
              buttonsConfig={buttons}
              title={dataSource.cardTitle}
              data={layout.columns}/>
          );
          break;

        case 'hero':
          markup.push(
            <Hero
              key={id}
              imageData={cardImage}
              title={cardTitle}
              summary={cardSummary.cardSummary}>
              {children}
            </Hero>
          );
          break;

        default:
          markup.push(<div>Hello</div>);
      }
    });
  }
  return markup;
};

export default LayoutGenerator;
